import * as Yup from 'yup';
import FormModel from './FormModel';

const {
    formField: {
        givenName,
        familyName,
        email,
        translationNeeded,
        consentGiven,
    }
} = FormModel;

let validationSchema = function (t, locale) {
    return Yup.object().shape({
        [givenName.name]: Yup
            .string()
            .matches(/^[\-A-Za-z ]*$/, t('Please enter a valid name.'))
            .max(35)
            .required(`${t(givenName.requiredErrorMsg)}`),
        [familyName.name]: Yup
            .string()
            .matches(/^[\-A-Za-z ]*$/, t('Please enter a valid name.'))
            .max(35)
            .required(`${t(familyName.requiredErrorMsg)}`),
        [email.name]: Yup.string().email().required(`${t(email.requiredErrorMsg)}`),
        //...(locale !== 'en') && {[translationNeeded.name]: Yup.string().required(`${t(translationNeeded.requiredErrorMsg)}`)},
        [consentGiven.name]: Yup.boolean()
            .required(t(consentGiven.requiredErrorMsg))
            .oneOf([true], t(consentGiven.requiredErrorMsg)),


    });
};

export default validationSchema;