import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {default as MaterialLink} from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import PersonalInformationForm from '../components/PersonalInformationForm';
import {useTranslation} from 'next-i18next';

import {serverSideTranslations} from 'next-i18next/serverSideTranslations';
import {CircularProgress, Grid} from "@mui/material";
import {Formik, Form} from "formik";
import validationSchema from '../components/FormModel/ValidationSchema';
import FormModel from '../components/FormModel/FormModel';
import initialValues from '../components/FormModel/InitialValues';
import Head from 'next/head';
import nextI18NextConfig from '../next-i18next.config.js';
import {useRouter} from 'next/router';
import Image from 'next/image';
import headerPic from '../public/img/lnob_logo_new.png';

const {formField} = FormModel;

export const getStaticProps = async ({locale}) => ({
  props: {
    ...(await serverSideTranslations(
      locale,
      ['common'],
      nextI18NextConfig
    )),
  },
});

function Copyright() {
  return (
    <div style={{textAlign: 'center'}}>
      <div style={{marginBottom: 10}}>
        <a href={"https://lnob.net/datenschutz/"} target={"_blank"} rel={"noreferrer"}>Datenschutz</a>
        {/*
                    <span style={{paddingRight: 10, paddingLeft: 10}}>
                <Link
                  href='/'
                  locale={'en'}
                >
                    English
                </Link>
            </span>
                  |
                  <span style={{paddingRight: 10, paddingLeft: 10}}>
                <Link
                  href='/'
                  locale={'de'}
                >
                    Deutsch
                </Link>
            </span>
                    */}
      </div>
      <div>
        {'Copyright © '}
        <MaterialLink color="inherit" href="https://civilfleet.org/">
          civilfleet-support e.V.
        </MaterialLink>{' '}
        {new Date().getFullYear()}
        {'.'}
      </div>
    </div>
  );
}

const theme = createTheme({
  palette: {
    primary: {
      light: '#ff7639',
      main: '#EA531D',
      dark: '#c30000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#5b6166',
      main: '#32373c',
      dark: '#0b1116',
      contrastText: '#000',
    },
  },
});

export default function Register() {
  const router = useRouter();
  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const registerUser = async values => {
    values.locale = router.locale;
    const res = await fetch('/api/register', {
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });

    const result = await res.json();
    setFormSubmitted(true);
  };

  const _handleSubmit = async (values, actions) => {
    await registerUser(values)
    actions.setTouched({});
    actions.setSubmitting(false);
    window.scrollTo(0, 0)
  };

  const {t} = useTranslation('common');

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <title>{t('PAGE_TITLE')}</title>
        <meta name="robots" content="noindex"/>
      </Head>
      <CssBaseline/>
      <Container component="main" maxWidth="lg" sx={{mb: 4}}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{mt: 5}}
        >
          <Grid item xs={3}>
            <Image
              alt="LNOB Logo"
              src={headerPic.src}
              width={250}
              height={23}
            />
          </Grid>

        </Grid>
        <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}}}>
          <Typography component="h1" variant="h4" align="center">
            {t('FORM_TITLE')}
          </Typography>
          <React.Fragment>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(t, router.locale)}
              onSubmit={_handleSubmit}
            >
              {({isSubmitting}) => (
                <Form>
                  {formSubmitted ? (
                    <React.Fragment>
                      <Typography variant="h5" gutterBottom>
                        {t('REGISTRATION_SUCCESS_TITLE')}
                      </Typography>
                      <div style={{whiteSpace: 'pre-wrap'}}>
                        {t('REGISTRATION_SUCCESS_TEXT')}
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <PersonalInformationForm formField={formField}/>
                      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                          variant="contained"
                          disabled={isSubmitting}
                          type="submit"
                          sx={{mt: 3, ml: 1}}
                        >
                          {t('Send Registration')}
                        </Button>
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                          />
                        )}
                      </Box>
                    </React.Fragment>
                  )}
                </Form>
              )}
            </Formik>
          </React.Fragment>
        </Paper>
        <Copyright/>
      </Container>
    </ThemeProvider>
  );
}
