import FormModel from './FormModel';

const {
    formField: {
        givenName,
        familyName,
        email,
        translationNeeded,
        consentGiven,
    }
} = FormModel;

let initialValues = {
    [givenName.name]: '',
    [familyName.name]: '',
    [email.name]: '',
    [translationNeeded.name]: '',
    [consentGiven.name]: false,
};

export default initialValues;