import * as React from "react";

export interface FormInterface {
    givenName: string;
    familyName: string;
    translationNeeded: string;
    email: string;
    questions: string;
    consentGiven: string;
}

let formModel = {
    formId: 'registrationForm',
    formField: {
        questions: {
            name: 'questions',
            label: 'Questions',
            helperText: 'If there is anything else you would like to add, please let us know.',
        },
        givenName: {
            name: 'givenName',
            label: 'Given Names *',
            requiredErrorMsg: 'Given names are required.',
            helperText: 'Please enter your Given Names here.',
        },
        familyName: {
            name: 'familyName',
            label: 'Family Name *',
            requiredErrorMsg: 'Family Name is required.',
            helperText: 'Please enter your Family Name here.',
        },
        translationNeeded: {
            name: 'translationNeeded',
            label: 'TRANSLATION_NEEDED_LABEL',
            helperText: 'TRANSLATION_NEEDED_HELPER',
            requiredErrorMsg: 'TRANSLATION_NEEDED_REQUIRED_ERROR_MSG',
        },
        consentGiven: {
            name: 'consentGiven',
            label: 'CONSENT_LABEL',
            requiredErrorMsg: 'Consent is required.',
        },
        email: {
            name: 'email',
            label: 'Email *',
            helperText: 'EMAIL_HELPER',
            requiredErrorMsg: 'Email Address is required.',
        },
    }
};

export default formModel;