import * as React from 'react';
import {
    FormHelperText,
    Grid,
    Typography,
} from "@mui/material";
import {TextField, Select, CheckboxWithLabel} from 'formik-mui';
import {useFormikContext, Field} from "formik";
import {FormInterface} from "./FormModel/FormModel";
import {useTranslation} from "next-i18next";
import {useRouter} from "next/router";


export default function PersonalInformationForm(props) {
    const router = useRouter();

    const {
        formField: {
            givenName,
            familyName,
            translationNeeded,
            email,
            questions,
            consentGiven,
        }
    } = props;

    const {t} = useTranslation('common');
    const formik = useFormikContext<FormInterface>();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                {t('Personal Information')}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Field
                        component={TextField}
                        id={givenName.name}
                        name={givenName.name}
                        label={t(givenName.label)}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        helperText={t(givenName.helperText)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field
                        component={TextField}
                        id={familyName.name}
                        name={familyName.name}
                        label={t(familyName.label)}
                        fullWidth
                        autoComplete="family-name"
                        variant="outlined"
                        helperText={t(familyName.helperText)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        id={email.name}
                        name={email.name}
                        label={t(email.label)}
                        fullWidth
                        autoComplete="email"
                        variant="outlined"
                        helperText={t(email.helperText)}
                    />
                </Grid>
                {false &&
                <Grid item xs={12}>
                  <Field
                    component={Select}
                    formControl={{fullWidth: true}}
                    formHelperText={{children: t(translationNeeded.helperText)}}
                    id={translationNeeded.name}
                    name={translationNeeded.name}
                    label={t(translationNeeded.label)}
                    native
                  >
                    <option value={""}></option>
                    <option value={"Yes"}>{t('Yes')}</option>
                    <option value={"No"}>{t('No')}</option>
                  </Field>
                </Grid>
                }
                <Grid item xs={12}>
                    <Field
                        component={TextField}
                        id={questions.name}
                        name={questions.name}
                        label={t(questions.label)}
                        fullWidth
                        variant="outlined"
                        helperText={t(questions.helperText)}
                        multiline
                    />
                </Grid>

                <Grid item xs={12}>
                    <Field component={CheckboxWithLabel}
                           type="checkbox"
                           name={consentGiven.name}
                           Label={{label: t(consentGiven.label)}}
                    />
                    {formik.getFieldMeta(consentGiven.name).error ?
                        <FormHelperText
                            error>{t(formik.getFieldMeta(consentGiven.name).error)}
                        </FormHelperText> : null}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
